<template>
  <div id="Warehouse">
    <div class="Warehouse-box1">
      <div class="content">
        <div class="row">
          <div class="col-3 content-item1">id</div>
          <div class="col-3 content-item1">奖励金额</div>
          <div class="col-3 content-item1">奖励类型</div>
          <div class="col-3 content-item1">奖励时间</div>
        </div>
        <div
          v-for="(item, index) in vipList"
          :key="index"
          class="row content-item1"
        >
          <div class="col-3">{{ index + 1 }}</div>
          <div class="col-3">{{ item.bean }}</div>
          <div class="col-3">{{ item.type_name }}</div>
          <div class="col-3">{{ item.created_at }}</div>
        </div>
      </div>
      <el-pagination
        background
        layout="total, pager"
        @current-change="PickChange"
        :page-size="TakingListper_page"
        :total="TakingListPageNum"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { vipMe } from "@/network/api.js";
import { mapMutations } from "vuex";
export default {
  name: "",
  data() {
    return {
      vipList: [],
      TakingListPageNum: 0,
      TakingListper_page: 0,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      vipMe(1).then((res) => {
        this.vipList = res.data.data.data;
        this.TakingListPageNum = Number(res.data.data.total);
        this.TakingListper_page = Number(res.data.data.per_page);
      });
    },
    PickChange(val) {
      this.PickShow = true;
      vipMe(val).then((res) => {
        this.vipList = res.data.data.data;
        this.TakingListPageNum = Number(res.data.data.total);
        this.TakingListper_page = Number(res.data.data.per_page);
      });
    },

    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },
  },

  watch: {},
};
</script>

<style scoped lang="scss">
.Warehouse-box1 {
  width: 100%;
  padding: 0.4rem 0.2rem;
  box-sizing: border-box;
  background-color: #131426;

  & .content {
    width: 100%;

    &-item1 {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
